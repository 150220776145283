@charset "utf-8";

/* ------------------------------------------------------------------
 * Name         : Barren - Simple Online Event Ticketing System Html Template
 * File         : style.css
 * Author       : Gambolthemes
 * Author URI   : gambol943@gmail.com
 * All Rights Reserved.
 * --------------------------------------------------------------------- */

/*===================== TABLE OF CONTENT =======================

1.  Default css
	- Google Fonts
	- Reset Css
	- Night Mode Btn
	- Scrollbar
	- Left Sidebar
	- Header section
	- Footer section
	- Main Index Page
	- Breadcrumb Title
2.  Index Homepage
3.	Explore Events
4.	Sign In and Sign Up Pages
5.	Event Detail View Page
6.	Checkout Page
7.	Profile Page
8.	Organisation Dashboard Page
9.	Create Events
10.	Pricing Page
11.	FAQ Page
12.	Contact Us Page
13.	Our Blog Page
14.	Blog Detail Page
15.	Help Center Page
16.	About Us Page
17.	Sell Tickets Online Page
18.	Create Page
19.	Coming Soon Page
20.	Error 404 Page
21.	Refer a Friend Page
22.	Terms & Condition Page
23.	Privacy Policy Page
24.	Booking Confirmed Page
25.	Invoice Page


/* ================================================================================== 
 Google Fonts
===================================================================================== */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;display=swap");
    /* ================================================================================== 
Reset Css
===================================================================================== */


html,
body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd,
q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot,
thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark,
audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
::-moz-selection {
  /* Code for Firefox */
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87);
}

::selection {
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87);
}

ol,
ul {
  list-style: none;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
a:hover {
  color: #3c52e9 !important;
  text-decoration: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
button {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  margin: 0;
  padding: 0;
}
a {
  color: #5065f6 ;
  text-decoration: none !important;
}
a:focus {
  text-decoration: none;
  outline: none;
}
a:hover {
  color: #3c52e9 !important;
  text-decoration: none !important;
}
button {
  outline: none;
}

button:focus {
  outline: none !important;
}

input {
  outline: none;
  border-radius: 3px;
}

textarea {
  outline: none;
  border-radius: 3px;
}

p {
  font-size: 14px;
  line-height: 26px;
  color: #717171;
  letter-spacing: 0.1px;
}
.modal-confirm {
  color: #636363;
  width: 325px;
  font-size: 14px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -5px;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}

.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #a882dd;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}

.modal-confirm.modal-dialog {
  margin-top: 80px;
}

.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #a882dd;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
  background: #8e54e0;
  outline: none;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

/* ================ Typography Css =============== */
h1 {
  font-size: 2em;
  /* 2x body copy size = 32px */
  line-height: 1.25;
  /* 45px / 36px */
  color: #000;
}
h2 {
  font-size: 1.625em;
  /* 1.625x body copy size = 26px */
  line-height: 1.15384615;
  /* 30px / 26px */
  color: #000;
}
h3 {
  font-size: 1.375em;
  /* 1.375x body copy size = 22px */
  line-height: 1.13636364;
  /* 25px / 22px */
  color: #000;
}
h4 {
  font-size: 1.125em;
  /* 1.125x body copy size = 18px */
  line-height: 1.11111111;
  color: #000;
}
h5 {
  font-size: 1.1em;
  /* 1.125x body copy size = 18px */
  line-height: 1.1;
  color: #000;
}
h6 {
  font-size: 1em;
  /* 1.125x body copy size = 18px */
  line-height: 1.05;
  color: #000;
}
blockquote {
  font-size: 1.25em;
  /* 20px / 16px */
  line-height: 1.25;
  /* 25px / 20px */
}
@media (min-width: 43.75em) {
  h1 {
    font-size: 2.5em;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
    color: #000;
  }
  h2 {
    font-size: 2em;
    /* 2x body copy size = 32px */
    line-height: 1.25;
    color: #000;
  }
  h3 {
    font-size: 1.5em;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
    color: #000;
  }
  h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
    color: #000;
  }
  blockquote {
    font-size: 1.5em;
    /* 24px / 16px = */
    line-height: 1.45833333;
    /* 35px / 24px */
  }
}
@media (min-width: 56.25em) {
  h1 {
    font-size: 3em;
    /* 3x body copy size = 48px */
    line-height: 1.05;
    /* keep to a multiple of the 20px line height 
	and something more appropriate for display headings */
  }
  h2 {
    font-size: 2.25em;
    /* 2.25x body copy size = 36px */
    line-height: 1.25;
  }
  h3 {
    font-size: 1.75em;
    /* 1.75x body copy size = 28px */
    line-height: 1.25;
  }
}

/* ================ CROSS BROWSER COMPATABILITY =============== */

nav,
nav ul li a,
nav ul li:hover > ul {
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

/* === Body Scrollbar === */

body::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
body::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
body::-webkit-scrollbar-corner {
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.875rem;
  line-height: 1.42857143;
  color: #333;
  background: #f5f7f9 !important;
}

/* --- Header --- */
.selected {
  border-bottom: 2px solid #00509d; /* Adjust the border style as needed */
}

.control:active {

  border-bottom: 2px solid #00509d!important; /* Adjust the border style as needed */

}
.selected {
border-bottom: 2px solid #00509d!important; /* Adjust the border style as needed */
}
.header-wrapper {
  background: #fff;
  position: fixed;
  width: 100%;
  box-shadow: 0px 0.052083333in 11.25pt -9px rgb(0 0 0 / 1%);
}

.header {
  position: relative;
  width: 100%;
}

.logo-inverse {
  display: none;
}

.main-logo img {
  width: 100%;
  padding-top: 0;
}
.error-input {
  border-color: #ff0000; /* Red border color */
}

.error-message {
  color: #ff0000; /* Red text color */
  font-size: 14px;
  margin-top: 5px;
}

.main-logo {
  width: 120px;
}

.res-main-logo {
  display: none;
}

#night-mode {
  padding: 0;
  font-size: 15px;
  background: #e8f7f7;
  color: #717171;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.bg-barren {
  background-color: #ffffff;
  box-shadow: 0px 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
}

.barren-head {
  height: 60px;
}

.navbar-toggler {
  color: #000;
  padding: 0.25rem 1rem 0.25rem 0 !important;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none !important;
}

.navbar-toggler-icon {
  height: auto !important;
}

.navbar-toggler-icon:hover {
  color: #00509d !important;
}

.create-btn i {
  margin-right: 10px;
}

.create-btn {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  height: 40px;
  display: inline-block;
  border: 2px solid #00509d;
  background-color: #00509d;
  padding: 8px 18px;
  border-radius: 3px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
}

.right-header ul li {
  display: inline-block;
  vertical-align: bottom;
}

.right-header ul li:first-child {
  margin-right: 25px;
}

.right-header ul li:last-child {
  margin-left: 22px;
}

.account-link {
  color: #1d1d1d;
}

.arrow-icon {
  margin-left: 5px;
}

.account-link img {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #efefef;
  padding: 2px;
  object-fit: cover;
}

.account-link.show img {
  border: 2px solid #00509d;
}

.dropdown-menu-account li {
  margin: 0 !important;
}

.dropdown-menu-account {
  padding: 0 !important;
  width: 250px;
  margin-top: 9px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  border: 1px solid #f1f1f1 !important;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.dropdown-menu-account li {
  margin: 0 !important;
  display: block !important;
}

.dropdown-account-header {
  text-align: center;
  padding: 20px;
}

.account-holder-avatar img {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #00509d;
  padding: 2px;
  object-fit: cover;
}

.dropdown-account-header h5 {
  font-size: 16px;
  margin-top: 13px;
  margin-bottom: 3px;
}

.dropdown-account-header p {
  display: block;
  color: #686868;
  font-size: 13px;
  margin-bottom: 0;
}

.profile-link {
  border-top: 1px solid #efefef;
  padding: 10px 0;
}

.dropdown-menu-account li .link-item {
  display: block;
  width: 100%;
  color: #000000;
  padding: 10px 20px;
}

.dropdown-menu-account li .link-item:hover {
  color: #00509d !important;
  background-color: #e8f7f7;
}

.nav-link {
  color: #000 !important;
}

.nav-link:hover {
  color: #00509d !important;
}

.barren-head .nav-item .nav-link.active,
.barren-head .nav-item .show > .nav-link {
  color: #00509d !important;
}

.close-btn {
  padding: 6px;
  font-size: 20px;
  background: #f1f2f3;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  width: 40px;
  height: 40px;
  border: 0;
}

.close-btn:hover,
.close-btn:focus {
  background: #f1f2f3;
  color: rgba(0, 0, 0, 1);
}

.offcanvas-top-area {
  display: none;
}

.offcanvas-create-btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #00509d;
  border: 2px solid #00509d;
  display: block;
  height: 50px;
  padding: 12px;
  border-radius: 3px;
}

.offcanvas-create-btn i {
  margin-right: 10px;
}

.offcanvas-create-btn:hover {
  color: #fff !important;
  background: #006cff;
  border: 2px solid #006cff;
}

.offcanvas-footer {
  border-top: 1px solid #efefef;
}

.offcanvas-social {
  padding: 30px 20px;
}

.offcanvas-social > h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
}

.offcanvas-social > .social-links > li {
  display: inline-block;
  margin-right: 10px;
}

.offcanvas-social > .social-links > li:last-child {
  margin-right: 0;
}

.offcanvas-social > .social-links li > .social-link {
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: block;
  border: 2px solid #00509d;
  border-radius: 100%;
  text-align: center;
  line-height: 38px;
  color: #00509d;
}

.offcanvas-social > .social-links li > .social-link:hover {
  color: #fff !important;
  border: 2px solid #00509d;
  background-color: #00509d;
}

.dropdown-submenu {
  width: 230px;
  margin-top: 13px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  border: 1px solid #f1f1f1 !important;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.dropdown-submenu .dropdown-item {
  display: block;
  font-size: 14px;
  width: 100%;
  color: #000000;
  padding: 10px 20px;
}

.dropdown-submenu .dropdown-item:hover {
  color: #00509d !important;
  background-color: #e8f7f7;
}

.dropdown-submenu .dropdown-item:focus {
  color: #00509d !important;
  background-color: transparent;
}

.submenu-item:after {
  content: "\f0da";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  float: right;
  font-size: 12px;
}

.pe_5 {
  padding-right: 37px !important;
}

/* --- Footer --- */

.footer {
  background: #00509d;
  color: #fff;
}

.footer-top {
  padding: 24px 0 64px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-content {
  margin-top: 38px;
}

.footer-content h4 {
  font-size: 22px;
  color: #fff;
  margin-bottom: 11px;
  font-weight: 400;
}

.footer-link-list li {
  display: block;
  line-height: 32px;
  font-size: 16px;
}

.footer-link {
  color: rgba(255, 255, 255, 0.8);
}

.footer-link:hover {
  color: #006cff !important;
}

.footer-content > .social-links {
  margin-top: 21px;
}

.footer-content > .social-links > li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.footer-content > .social-links > li:last-child {
  margin-right: 0;
}

.footer-content > .social-links li > .social-link {
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: block;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  text-align: center;
  line-height: 39px;
  color: rgba(255, 255, 255, 0.7);
}

.footer-content > .social-links li > .social-link:hover {
  color: #fff !important;
  border: 2px solid #006cff;
  background-color: #006cff;
}

.download-app-link {
  display: inline-block;
  height: 38px;
  margin-top: 12px;
}

.download-btn {
  transition: all 0.12s ease-in-out 0s;
  text-decoration: none;
  margin-right: 7px;
  vertical-align: inherit;
}

.download-btn img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
  transition: all 0.12s ease-in-out 0s;
  opacity: 1;
}

.download-btn img:hover {
  opacity: 0.8;
}

.footer-copyright-text p {
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.3px;
}

.footer-copyright-text {
  text-align: center;
  padding: 30px 0;
}

/* --- Index Page --- */

.wrapper {
  margin-top: 60px;
}

.hero-banner {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/banner/e7.jpeg"); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0 80px;

}

.hero-banner-content {
  text-align: center;
}
.hero-banner-content-home {
  text-align: center;
}
.hero-banner-content-about {
  text-align: center;
}


.hero-banner-content h2 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 15px;
}
.hero-banner-content-about h2 {
  font-size: 3.75rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 15px;
  color: #00509d;
  text-align: center;
}

.hero-banner-content-home h2 {
  font-size: 85px;
  letter-spacing: 0.1px;
  line-height: 90px;
  font-weight: 600;
  margin-bottom: 15px;
}


.hero-banner-content p {
  font-size: 22px;
  color: #000000;
  margin-bottom: 33px;
  padding: 0 30px;
  line-height: 32px;
}
.hero-banner-content-home p {
  font-size: 22px;
  color: #000000;
  margin-bottom: 33px;
  padding: 0 30px;
  line-height: 32px;
}
.hero-banner1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/banner/e7.jpeg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0 180px;

}

.hero-banner1-content {
  text-align: center;
}

.hero-banner1-content h2 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 15px;
}

.hero-banner1-content p {
  font-size: 22px;
  color: #000000;
  margin-bottom: 33px;
  padding: 0 30px;
  line-height: 32px;
}

.main-btn {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  background: #00509d;
  height: 52px;
  display: inline-block;
  line-height: 49px;
  /* padding: 0 30px; */

  border-radius: 3px;
  border: 1px solid #00509d;
}

.btn-hover:hover {
  color: #fff !important;
  background: #006cff;
  border: 1px solid #006cff;
}

.co-main-btn {
  color: #000;
  background: #fff !important;
  border-radius: 3px;
  border: 1px solid #bbb;
}

.co-main-btn:hover {
  color: #000 !important;
  background: #e8f7f7 !important;
  border-radius: 3px;
  border: 1px solid #bbb;
}

.co-btn-width {
  width: 270px;
}

.p-80 {
  padding: 57px 0 64px;
}

.explore-events {
  margin-top: 0;
}

.flex-d {
  display: flex;
  align-items: center;
  width: 100%;
}

.title-view-link {
  margin-left: auto;
  color: #717171;
  font-size: 14px;
  font-weight: 500;
}

.title-view-link i {
  margin-left: 9px;
}

.title-view-link:hover {
  color: #00509d !important;
}

.duration-description{
  /* Limit the paragraph to display only two lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  /* Enable text wrapping */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; /* This is important to prevent text from being truncated */
}

.main-title h3 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 30px;
  color: #000;
  line-height: 52px;
  text-align: center;
}

.filter-tag {
  text-align: center;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  padding: 31px 0 0;
  margin-bottom: 35px;
}

.filter-tag a.active,
.filter-tag a:hover {
  background: #efefef;
  color: #000 !important;
}

.filter-tag a {
  font-size: 12px;
  color: #717171;
  border: 1px solid #efefef;
  background: #fff;
  height: 32px;
  padding: 7px 20px;
  border-radius: 30px;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
}

.mixitup-control-active {
  background: #fff;
  color: #00509d !important;
}

.controls {
  margin-bottom: 15px;
}

.control {
  position: relative;
  display: inline-block;
  background: #fff;
  height: 40px;
  padding: 0 20px;
  margin-bottom: 5px;
  margin-right: 2px;
  cursor: pointer;
  border: transparent;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: background 150ms;
}

.control:first-of-type {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.control[data-filter]:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  background: transparent;
  transition: background-color 150ms, border-color 150ms;
}

.mixitup-control-active[data-filter]:after {
  background: transparent;
  border-bottom: 3px solid #00509d;
  color: #00509d;
}

.main-card {
  position: relative;
  border: 1px solid #efefef;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
}

.event-thumbnail {
  width: 100%;
  position: relative;
  padding: 0;
}

.thumbnail-img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.event-thumbnail > .thumbnail-img::after {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.thumbnail-img:hover {
  outline: 0 !important;
  color: #000;
  transition: 0.3s;
  text-decoration: none;
}

.event-thumbnail a img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: transform 0.5s ease;
}

.event-thumbnail a:hover img {
  transform: scale(1.05);
}

.event-content {
  padding: 15px 20px 20px;
}

.event-content > .event-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  display: block;
  line-height: 25px;
  min-height: 50px;
  margin-bottom: 3px;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.event-content > .event-title:hover {
  color: #000 !important;
}

.duration-price-remaining {
  display: flex;
  width: 100%;
  align-items: center;
}

.remaining {
  font-size: 14px;
  font-weight: 400;
  margin-left: auto;
  color: #717171;
}

.duration-price {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.remaining i {
  font-size: 12px;
  margin-right: 7px;
  transform: rotate(300deg);
}

.event-footer {
  background: rgb(106, 192, 69, 3%);
  padding: 10px 20px;
}

.event-timing {
  display: flex;
  align-items: center;
  color: #717171;
  font-size: 13px;
}

.publish-time {
  margin-left: auto;
}

.publish-date {
  display: flex;
  align-items: center;
}

.dot {
  font-size: 4px;
  margin: 0 10px;
}

.bookmark-icon {
  color: #fff;
  font-size: 20px;
  position: absolute;
  z-index: 101;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background-color: #fc440f;
  display: block;
  height: 45px;
  width: 45px;
  line-height: 46px;
  border-radius: 30px;
  transition: all 0.4s;
}

.bookmark-icon:before,
.bookmark-icon:after {
  transition: opacity 0.3s, transform 0.4s cubic-bezier(0, -0.41, 0.19, 2.5);
  font-family: "Font Awesome 6 Free";
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  top: 0px;
}

.bookmark-icon:before {
  content: "\f02e";
  transform: scale(0.95);
}

.bookmark-icon:after {
  content: "\f02e";
  opacity: 0;
  transform: scale(0.3);
  font-weight: 900;
}

.bookmark-icon.bookmarked:after {
  opacity: 1;
  transform: scale(0.95);
}

.bookmark-icon:hover,
.like-icon.bookmarked {
  background-color: #00509d;
  color: #fff;
}

.bookmark-icon.bookmarked:before {
  opacity: 0;
}

.browse-btn {
  text-align: center;
  margin-top: 40px;
}

.host-engaging-event-block {
  background: #fff;
}

.main-title p {
  font-size: 16px;
  line-height: 30px;
  margin: 10px 0 33px;
  color: #717171;
}

.host-item {
  padding: 30px;
}

.host-img {
  display: block;
  margin-bottom: 20px;
}

.host-img img {
  width: 50px !important;
}

.host-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
  color: #000;
}

.host-item p {
  margin-bottom: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #00509d !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: #efefef !important;
}

.owl-nav .owl-prev {
  left: -20px;
  font-size: 20px !important;
  position: absolute;
  top: 45%;
  padding: 0 !important;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  color: #717171 !important;
  background: #fff !important;
  border-radius: 3px !important;
  border: 1px solid #efefef !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.owl-nav .owl-next {
  font-size: 20px !important;
  position: absolute;
  right: -20px;
  top: 45%;
  padding: 0 !important;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  color: #717171 !important;
  background: #fff !important;
  border-radius: 3px !important;
  border: 1px solid #efefef !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #00509d !important;
  border: 1px solid #00509d !important;
}

.owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #00509d !important;
  background: #00509d !important;
}

.engaging-slider .owl-nav .owl-prev {
  top: 35%;
}

.engaging-slider .owl-nav .owl-next {
  top: 35%;
}

.feature-group-list {
  border-top: 2px solid #efefef;
  padding-top: 3px;
}

.mt-46 {
  margin-top: 57px !important;
}

.feature-item {
  margin-top: 37px;
}

.feature-icon {
  display: block;
  margin-bottom: 24px;
}

.feature-icon img {
  width: 50px;
}

.feature-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
  color: #000;
}

.feature-item p {
  margin-bottom: 0;
}

.host-step-block {
  background-color: #fff;
}

.step-link {
  font-size: 22px;
  font-weight: 100;
  position: relative;
  box-sizing: border-box;
  width: 25%;
  display: block;
  border: none;
  border-bottom: 1px solid #efefef;
  padding: 1rem;
  color: #bbb;
  background: transparent;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.step-tabs .step-link.active,
.step-tabs .show > .step-link {
  color: #fff;
  background-color: #00509d;
}

.step-tabs .step-link:hover {
  background-color: #e8f7f7;
}

.step-tabs .step-link.active span,
.step-tabs .show > .step-link span {
  color: #fff !important;
}

.step-link span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.step-tabs .step-link.active:hover {
  color: #fff;
  background-color: #00509d;
}

.step-text {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding: 37px 0 0;
  margin-bottom: 10px;
}

.step-item {
  margin-top: 37px;
}

.step-icon {
  display: block;
  margin-bottom: 24px;
}

.step-icon img {
  width: 50px;
}

.step-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
  color: #000;
}

.step-item p {
  margin-bottom: 0;
}

.testimonial-slider-area {
  border-top: 2px solid #efefef;
  padding-top: 38px;
}

.testimonial-content {
  padding: 30px;
  position: relative;
}

.testimonial-text p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 30px;
}

.testimonial-user-dt {
  margin-top: 33px;
}

.testimonial-user-dt h5 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.testimonial-user-dt span {
  font-size: 14px;
  color: #717171;
  margin: 10px 0;
  display: block;
}

.testimonial-user-dt ul li {
  display: inline-block;
}

.testimonial-user-dt ul li i {
  color: #ffad21;
}

.quote-icon {
  position: absolute;
  right: 30px;
  font-size: 60px;
  bottom: 3%;
  color: #efefef;
}

.our-organisations-block {
  background-color: #00509d;
}

.our-organisations-block .main-title h3 {
  color: #fff;
}

.organisations-area {
  margin-top: 64px;
}

.sponsor {
  text-align: center;
  line-height: 7;
}

.sponsor a img:hover {
  opacity: 1;
}

.sponsor a img {
  display: inline-block !important;
  width: auto !important;
  max-width: 100%;
  height: auto;
  opacity: 0.8;
  vertical-align: bottom;
}

/* --- Explore Events --- */

.search-form {
  margin-top: 20px;
}


.search-input {
  width: 100%;
}

.input-group {
  width: 100%;
}

.input-group-text {
  background-color: #fff; /* Adjust the background color as needed */
  border: none;
  cursor: pointer;
}

.fa-search {
  color: #333; /* Adjust the icon color as needed */
}


.search-category .bootstrap-select .dropdown-menu li a span.text {
  margin-left: 7px !important;
}

.search-category
  .bootstrap-select
  .dropdown-toggle
  .filter-option-inner-inner
  i {
  margin-right: 7px !important;
}

.form-control {
  font-size: 14px !important;
  color: black !important;
  background-color: #fff !important;
  border: 1px solid #efefef !important;
  border-radius: 3px !important;
  transition: none !important;
  padding: 0 15px !important;
  min-height: 40px;
  font-weight: 500;
}

.form-control:focus {
  color: #717171 !important;
  background-color: #fff !important;
  border-color: #a2ff7a !important;
  box-shadow: none !important;
}

.form-textarea {
  font-size: 14px !important;
  color: #717171 !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #efefef !important;
  border-radius: 3px !important;
  transition: none !important;
  padding: 0 15px !important;
  display: block;
  width: 100%;
  min-height: 90px;
  padding: 0.8rem 1rem !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-textarea:focus {
  color: #717171 !important;
  background-color: #f9f9f9 !important;
  border-color: #a2ff7a !important;
  box-shadow: none !important;
}

.bs-searchbox .form-control {
  width: 100%;
  float: none;
  margin-bottom: 7px !important;
}

.search-form .btn-light {
  display: block !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #717171 !important;
  background-color: #fff !important;
  border: 1px solid #efefef !important;
  padding: 5px 20px !important;
  min-height: 50px !important;
  border-radius: 3px !important;
  transition: none !important;
}

.btn-light {
  display: block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #717171 !important;
  background-color: #fff !important;
  border: 1px solid #efefef !important;
  padding: 5px 20px !important;
  min-height: 40px !important;
  border-radius: 3px !important;
  transition: none !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100% !important;
}

.bootstrap-select .dropdown-menu.inner {
  padding: 0px !important;
  border: 0 !important;
  box-shadow: none !important;
  display: inline-block;
}

.main-form .bootstrap-select .dropdown-toggle:focus,
.main-form .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  outline: none !important;
  outline-offset: -2px;
  border-color: #a2ff7a !important;
  box-shadow: none !important;
}

.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
  color: #fff !important;
  border-radius: 0 !important;
  background-color: #00509d !important;
}

.bootstrap-select.dropdown-item.active,
.bootstrap-select .dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #00509d !important;
}

.bootstrap-select .dropdown-item {
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 10px 20px !important;
}

.bootstrap-select > .dropdown-toggle:after {
  margin-left: -10px !important;
}

.dropdown-menu {
  border-radius: 3px !important;
  background-color: #fff !important;
  border: 1px solid #f1f1f1 !important;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

/* --- Sign In and Sign Up Pages --- */

.app-form {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  min-height: 100vh;
}

.app-form-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-size: cover;

  min-height: 100vh;
  /* background-color: #0011a3; */
  /* background-image: url(../images/onb.jpg); */
  animation: slideshow 25s infinite; 
  width: 500px;
  padding: 50px;
}

@keyframes slideshow {
  0% { background-image: url('../images/e7.jpeg'); }
  20% { background-image: url('../images/banner/e8.jpeg'); }
  40% { background-image: url('../images/banner/e2.jpeg'); }
  60% { background-image: url('../images/e7.jpeg'); }
  80% { background-image: url('../images/onb.jpg'); }
  100% { background-image: url('../images/free.jpg'); }
}


.sidebar-sign-logo {
  width: 140px;
}

.sidebar-sign-logo img {
  max-width: 100%;
}

.app-form-content {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.app-form,
.app-form-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}

.app-form-content {
  padding: 50px 0;
  background: #fff;
}

.app-top-items {
  display: flex;
  align-items: center;
  width: 100%;
}

.app-top-right-link {
  margin-left: auto;
  margin-top: 5px;
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-register-link {
  margin-left: 5px;
  color: #00509d;
  font-weight: 500;
}

.sidebar-register-link:hover {
  color: #006cff !important;
}

.signup-link {
  margin-left: 5px;
  color: #00509d;
  font-weight: 500;
}

.signup-link:hover {
  color: #006cff !important;
}

.registration-title {
  margin-top: 35px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.pass-show-eye {
  color: #717171;
  font-weight: 600;
  position: absolute;
  background: transparent;
  right: 5px;
  top: 5px;
  min-height: 40px;
  padding: 0 15px;
  line-height: 40px;
  margin-bottom: 0;
  border-radius: 3px;
  cursor: pointer;
}

.pass-show-eye:hover {
  color: #00509d;
}

.field-password {
  position: relative;
}

.forgot-pass-link {
  position: absolute;
  top: 0;
  right: 0;
  color: #00509d;
  font-weight: 500;
}

.forgot-pass-link:hover {
  color: #006cff !important;
}

.divider {
  position: relative;
  text-align: center;
  z-index: 1;
  margin: 30px 0;
}

.divider:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 1px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: rgba(112, 112, 112, 0.2);
}

.divider span {
  background: #fff;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.hr-line-dt {
  position: absolute;
  left: 0;
  top: -10px;
  padding: 0 20px 0 20px;
  background: #f9f9f9;
  color: #717171;
  font-size: 14px;
  font-weight: 500;
}

.new-sign-link {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 35px;
}

.copyright-footer {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding: 0 30px;
}

.sign_sidebar_text h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #fff;
}

.h_40 {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.h_50 {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}

.min-width {
  min-width: 130px;
  min-height: 40px;
  line-height: 39px;
  padding: 0 !important;
}

.sign-logo {
  display: none;
}

.sign-logo img {
  width: 100%;
  padding-top: 0;
  width: 130px;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
  display: block;
}

.social-login-btn {
  display: block;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 50px;
  border: 1px solid #efefef;
  background: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
}

.social-login-btn:hover {
  background: #f5f5f5;
}

.registration .form-control {
  background-color: #f9f9f9 !important;
}

.agree-text {
  padding-top: 20px;
  line-height: 26px;
  font-size: 14px;
  color: #000;
}

.agree-text a {
  color: #00509d;
}

.agree-text a:hover {
  color: #006cff !important;
  text-decoration: underline !important;
}

/* --- Event Detail View Page --- */

.breadcrumb-block {
  /* background-image: url(../images/pablo3.jpg); */
background-color: #00509d !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0;
}

.barren-breadcrumb .breadcrumb .breadcrumb-item a {
  color: #000;
}

.barren-breadcrumb .breadcrumb .breadcrumb-item a:hover {
  color: #00509d !important;
}

.barren-breadcrumb .breadcrumb .breadcrumb-item.active {
  color: #fff !important;
  font-size: 24px;
  font-weight: 400;
}

.barren-breadcrumb ol.breadcrumb {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
  width: 100%;
}

.barren-breadcrumb .breadcrumb .breadcrumb-item {
  display: inline;
}

.barren-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  float: none !important;
  color: #000 !important;
}

.event-top-dts {
  display: flex;
  width: 100%;
  position: relative;
}

.event-top-date {
  height: 100px;
  width: 100px;
  border: 1px solid #efefef;
  background: #fff;
  text-align: center;
  border-radius: 3px;
}

.event-month {
  display: block;
  background: #00509d;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 5px 0;
  text-transform: uppercase;
}

.event-date {
  font-size: 30px;
  padding: 8px 0;
  display: block;
  font-weight: 500;
  color: #000;
}

.event-top-dt {
  margin-left: 27px;
}

.event-type-name {
  font-size: 16px;
  font-weight: normal;
  color: #717171;
  position: relative;
}

.event-top-dt .event-top-info-status .event-type-name {
  padding-left: 11px;
  margin-right: 10px;
}

.event-top-dt .event-top-info-status .event-type-name:nth-child(1) {
  padding-left: 0;
}

.event-top-dt .event-top-info-status .event-type-name:nth-child(1) i {
  padding-right: 10px;
}

.event-main-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 18px;
  color: #000;
}

.details-hr:after {
  content: "";
  width: 4px;
  height: 4px;
  background: #000;
  border-radius: 100%;
  display: inline-block;
  left: 0;
  top: 8px;
  position: absolute;
}

.event-img img {
  width: 100%;
  border-radius: 5px;
}

.main-event-dt {
  margin-top: 40px;
}

.main-event-content {
  margin-top: 37px;
}

.main-event-content h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #000;
}

.main-event-content p {
  margin-bottom: 27px;
}

.main-event-content p:last-child {
  margin-bottom: 0;
}

.booking-ends h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000;
  text-transform: uppercase;
  text-align: center;
}

.booking-ends {
  padding: 37px 20px 0;
}

.event-right-dt {
  margin-top: 40px;
}

.countdown {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0;
}

.countdown-item {
  width: 100%;
  padding: 12px 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #00509d;
  margin-right: 5px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 3px rgb(0 0 0 / 5%);
}

.countdown-item:last-child {
  margin-right: 0;
}

.countdown-item span {
  display: block;
  font-size: 26px;
  font-weight: 400;
}

.event-dt-right-group {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0 20px;
  margin-bottom: 37px;
}

.event-dt-right-content {
  margin-left: 15px;
}

.event-dt-right-icon i {
  font-size: 16px;
  color: #00509d;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #e8f7f7;
  border-radius: 50%;
}

.event-dt-right-content h4 {
  font-size: 14px;
  margin-bottom: 13px;
  font-weight: 400;
  color: #717171;
}

.event-dt-right-content h5 {
  font-size: 16px;
  margin-bottom: 0;
  color: #000;
  font-weight: 500;
  font-family: inherit;
}

.event-dt-right-content a {
  color: #00509d;
  font-weight: 500;
  margin-top: 13px;
  display: inline-block;
}

.event-dt-right-content a:hover {
  color: #006cff !important;
}

.booking-btn {
  padding: 0 20px 20px;
}

.add-to-calendar .dropdown-item {
  display: block;
  font-size: 14px;
  width: 100%;
  color: #000000;
  font-weight: 400;
  padding: 10px 20px;
  margin-top: 0;
}

.dropdown-item {
  display: block !important;
  font-size: 14px !important;
  width: 100% !important;
  color: #000000 !important;
  font-weight: 400 !important;
  padding: 10px 20px !important;
  margin-top: 0 !important;
}

.dropdown-item:hover {
  color: #00509d !important;
  background-color: #e8f7f7 !important;
}

.share-save-btns {
  text-align: center;
  margin-top: 30px;
}

.sv-btn {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  height: 40px;
  width: 110px;
  border: 1px solid #efefef;
  border-radius: 3px;
  background: #fff;
}

.sv-btn:hover {
  color: #000;
  border: 1px solid #efefef;
  background: #e8f7f7;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.more-events {
  margin-top: 55px;
  border-top: 1px solid #efefef;
  padding-top: 55px;
}

.view-all-link {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.view-all-link:hover {
  color: #00509d !important;
}

/* --- Checkout Page --- */

.checkout-title {
  border-bottom: 2px solid #efefef;
  padding-bottom: 25px;
}

.bp-title h4 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  padding: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #efefef;
}

.bp-content {
  padding: 0 30px 30px;
}

.bp-form .form-control {
  background-color: #f9f9f9 !important;
}

.bp-form .btn-light {
  display: block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #717171 !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #efefef !important;
  padding: 5px 20px !important;
  min-height: 50px !important;
  border-radius: 3px !important;
  transition: none !important;
}

.checkout-block {
  margin-top: 40px;
}

.order-summary {
  margin-top: 40px;
}

.order-summary-content {
}

.event-order-dt {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.event-thumbnail-img img {
  object-fit: cover;
  width: 110px;
  height: 100px;
  border-radius: 5px;
}

.event-order-dt-content {
  margin-left: 20px;
}

.event-order-dt-content h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  color: #000;
}

.event-order-dt-content span {
  font-size: 14px;
  font-weight: 400;
  color: #717171;
  display: block;
  margin-bottom: 10px;
}

.event-order-dt-content .category-type {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  font-family: inherit;
}

.order-total-block {
  margin-top: 30px;
}

.order-total-dt {
  display: flex;
  width: 100%;
  align-items: center;
  color: #717171;
  font-weight: 600;
}

.order-total-dt div {
  font-size: 16px;
  margin-bottom: 20px;
}

.order-number {
  margin-left: auto;
}

.order-text {
  color: #000;
}

.ttl-clr {
  color: #000;
}

.divider-line {
  display: block;
  width: 100%;
  height: 1px;
  background: #efefef;
  margin-bottom: 20px;
}

.coupon-code-block .form-control {
  background-color: #f9f9f9 !important;
}

.apply-btn {
  position: absolute;
  right: 5px;
  height: 40px;
  bottom: 5px;
  padding: 0 20px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  background: #00509d;
  display: inline-block;
  border-radius: 3px;
  border: 0;
}

.confirmation-btn {
  text-align: center;
}

.confirmation-btn span {
  font-size: 14px;
  color: #000;
  margin-top: 13px;
  display: block;
}

.select-tickets-block {
  padding: 0 20px 40px;
}

.select-ticket-action {
  display: flex;
  width: 100%;
  align-items: center;
}

.select-tickets-block h6 {
  font-size: 18px;
  font-family: inherit;
  color: #000;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 27px;
}

.ticket-price {
  font-size: 18px;
  font-weight: 500;
  color: #717171;
}

.quantity {
  margin-left: auto;
}

.counter {
  width: 150px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter input {
  width: 40px;
  border: 0;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: transparent;
  color: #000;
  appearance: none;
  outline: 0;
}

.counter span {
  display: block;
  font-size: 24px;
  padding: 0;
  line-height: 26px;
  cursor: pointer;
  color: #000;
  user-select: none;
  background: #fff;
  border: 2px solid #bbb;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
}

.counter span:hover {
  color: #fff;
  background: #00509d;
  border: 2px solid #00509d;
}

.select-tickets-block p {
  margin-bottom: 20px;
  margin-top: 20px;
}

.xtotel-tickets-count {
  border-top: 1px solid #efefef;
  padding-top: 20px;
}

.x-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 13px;
}

.xtotel-tickets-count h4 {
  font-size: 22px;
  margin-bottom: 0;
  color: #000;
}

/* --- Profile Page --- */

.hero-cover-block {
  height: 260px;
  position: relative;
}

.hero-cover {
  position: absolute;
  inset: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden;
  background-color: rgba(80, 101, 246, 0.2);
}

.hero-cover-img {
  background-size: cover;
  background-position: center center;
  position: absolute;
  inset: 0px;
  background-image: url(../images/banners/hero-cover-01.jpg);
  filter: none;
  transition: all 0.12s ease-in-out 0s;
  opacity: 1;
}

.user-dt-block {
  padding-bottom: 64px;
}

.user-left-dt {
  text-align: center;
  padding: 30px;
  margin-top: -70px;
}

.upload-cover {
  display: inline-block;
  color: #e44d3a;
  font-size: 16px;
  position: absolute;
  top: 30px;
  right: 0;
  font-weight: 600;
  margin-right: 0;
  width: 100%;
}

.upload-cover .cover-img-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  float: left;
}

.upload-cover .cover-img-btn [type="file"] + label {
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 12px 20px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0;
  float: right;
}

.upload-cover .cover-img-btn [type="file"] + label:hover {
  background: #006cff;
  color: #fff;
}

.user-avatar-img {
  position: relative;
}

.user-avatar-img img {
  width: 130px;
  height: 130px;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #00509d;
  padding: 2px;
  object-fit: cover;
}

.avatar-img-btn {
  display: inline-block;
  color: #e44d3a;
  font-size: 16px;
  position: absolute;
  bottom: 4px;
  left: 64%;
  transform: translateX(-50%);
  margin-right: 0;
}

.avatar-img-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  float: left;
}

.avatar-img-btn [type="file"] + label {
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0;
  float: right;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.avatar-img-btn [type="file"] + label:hover {
  background: #006cff;
  color: #fff;
}

.user-dts .user-name {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-top: 23px;
}

.ff-block {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 30px;
}

.ff-block a {
  font-size: 14px;
  color: #000;
  display: inline-block;
  width: 50%;
}

.ff-block a:hover {
  color: #00509d !important;
}

.ff-block a span {
  display: block;
  font-size: 18px;
  font-weight: 500;
}

.ff-block a:first-child {
  border-right: 1px solid #efefef;
}

.verify-badge {
  margin-left: 8px;
  font-size: 15px;
  color: #00509d !important;
}

.user-description {
  margin-top: 30px;
}

.user-btns {
  margin-top: 30px;
}

.user-email {
  font-size: 16px !important;
  margin-top: 15px;
  display: block;
}

.profile-social-link {
  margin-top: 37px;
}

.profile-social-link h6 {
  font-size: 16px;
  font-weight: 600;
  text-transform: inherit;
  color: #000;
  margin-bottom: 20px;
}

.social-links > .social-link {
  font-size: 18px;
  width: 40px;
  height: 40px;
  border: 2px solid #00509d;
  border-radius: 100%;
  text-align: center;
  color: #00509d;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 10px;
  margin-right: 7px;
  padding: 10px 9px;
}

.social-links > .social-link:hover {
  color: #fff !important;
  border: 2px solid #00509d;
  background-color: #00509d;
}

.user-right-dt {
  text-align: center;
  padding: 0;
  margin-top: -70px;
}

.garren-line-tab {
  border: 1px solid #efefef;
  border-radius: 3px;
  background-color: #fff;
}

.border-bottom {
  border-bottom: 1px solid #efefef !important;
}

.garren-line-tab .nav-link {
  font-size: 14px;
  font-weight: 400;
  color: #717171;
  position: relative;
  padding: 10px !important;
  border-radius: 3px;
}

.garren-line-tab .nav-link.active,
.garren-line-tab .nav-link.active:hover {
  color: #fff !important;
  background-color: #00509d !important;
}

.garren-line-tab .nav-link:hover {
  color: #00509d !important;
}

.garren-line-tab .nav-link i {
  font-size: 14px;
  margin-right: 8px;
}

.right-profile {
  margin-top: 25px;
}

.tab-link {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;
  width: 33.33%;
  display: block;
  border: none;
  border-bottom: 3px solid transparent;
  padding: 1rem;
  color: #717171;
  background: transparent;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.custom-tabs .tab-link.active,
.tab-tabs .show > .tab-link {
  color: #00509d;
  background-color: #fff;
  border-bottom: 3px solid #00509d;
}

.custom-tabs .tab-link:hover {
  color: #00509d;
  background-color: #fff;
  border-bottom: 3px solid transparent;
}

.custom-tabs .tab-link.active span,
.tab-tabs .show > .tab-link span {
  color: #fff;
}

.custom-tabs .tab-link.active:hover {
  color: #00509d;
  background-color: #fff;
  border-bottom: 3px solid #00509d;
}

.setting-step {
  border-bottom: 1px solid #efefef;
}

.setting-step:last-child {
  border-bottom: 0;
}

.chk-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.setting-title {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  color: #000;
}

.btn-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 22px;
  margin: 14px 5px;
}

.btn-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.btn-switch input:checked + .checkbox-slider {
  background: #22b0af;
}

.btn-switch .checkbox-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 22px;
  width: 44px;
  border-radius: 16px;
  background-color: #edf1f5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.btn-switch .checkbox-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  top: calc(50% - 18px / 2);
  border-radius: 50%;
  background-color: white;
  filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.btn-switch input:checked + .checkbox-slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.password-des h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #000;
}

.password-des p {
  margin-bottom: 30px;
}

.card-top {
  display: flex;
  width: 100%;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
}

.card-event-img {
  width: 170px;
  height: 110px;
  margin-right: 20px;
}

.card-event-img img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
}

.card-event-dt h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #000;
}

.invoice-id {
  color: #717171;
}

.invoice {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items vertically */
  justify-content: center;
  min-height: 100vh;
}

.card-bottom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.card-bottom-item {
  display: flex;
  width: 25%;
  align-items: center;
  padding: 20px;
}

.card-icon i {
  font-size: 16px;
  color: #00509d;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 41px;
  background: #e8f7f7;
  border-radius: 50%;
}

.card-dt-text {
  margin-left: 15px;
}

.card-dt-text h6 {
  font-size: 13px;
  color: #717171;
}

.card-dt-text span {
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.card-dt-text a {
  color: #00509d;
  font-weight: 500;
  font-size: 14px;
}

.card-dt-text a:hover {
  color: #006cff !important;
}

.edit-btn {
  position: absolute;
  right: 0;
  top: 10px;
}

.about-details {
  padding: 30px;
}

.about-step {
  border-bottom: 1px solid #efefef;
  padding-bottom: 25px;
  padding-top: 25px;
}

.about-step:first-child {
  padding-top: 0;
}

.about-step:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.about-step h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 13px;
}

.about-step span {
  font-size: 18px;
  color: #717171;
  font-weight: 500;
  margin-bottom: 0;
}

.close-model-btn {
  box-sizing: content-box;
  font-size: 14px;
  width: 1.5em;
  height: 1.5em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.close-model-btn:hover {
  color: #00509d;
  opacity: 1;
}

.modal-title {
  font-size: 16px;
  font-weight: 500;
  font-family: inherit;
  color: #000;
}

.modal-header {
  border-bottom: 1px solid #efefef !important;
}

.modal-footer {
  border-top: 1px solid #efefef !important;
}

.modal-body {
  padding: 0 !important;
}

.model-content {
  padding: 0 30px 30px;
}

.model-content .form-control {
  background-color: #f9f9f9 !important;
}

.address-title {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
  padding-bottom: 13px;
  margin-top: 30px;
  color: #000;
}

.model-content .btn-light {
  min-height: 40px !important;
  display: block !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #717171 !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #efefef !important;
  padding: 5px 20px !important;
  border-radius: 3px !important;
  transition: none !important;
}

.my-event-tabs {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
}

.event-link {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;
  width: 33.333%;
  display: block;
  border: none;
  border-bottom: 3px solid transparent;
  padding: 1rem;
  color: #bbb;
  background: transparent;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.my-event-tabs .event-link.active,
.my-event-tabs .show > .event-link {
  color: #00509d;
  background-color: #fff;
  border-bottom: 3px solid #00509d;
}

.my-event-tabs .event-link.active span,
.my-event-tabs .show > .event-link span {
  color: #00509d;
}

.event-link span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-top: 10px;
}

.my-event-tabs .event-link.active:hover {
  color: #00509d;
  background-color: #fff;
  border-bottom: 3px solid #00509d;
}

.event-count {
  background: #e8f7f7;
  display: inline-block !important;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  line-height: 36px;
}

.my-event-tabs .event-link.active .event-count,
.my-event-tabs .show > .event-link .event-count {
  color: #fff;
  background: #00509d;
}

.profile-event-content {
  padding: 15px 20px 20px;
}

.profile-event-content > .event-title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  display: block;
  line-height: 25px;
  min-height: 50px;
  margin-bottom: 33px;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.profile-event-content > .event-title:hover {
  color: #000 !important;
}

.profile-event-footer {
  padding: 0 20px 20px;
}

.event-btn-group {
  margin-top: 20px;
}

.esv-btn {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  height: 40px;
  min-width: 110px;
  border: 1px solid #efefef;
  border-radius: 3px;
  background: #fff;
  padding: 0 20px;
}

.esv-btn:hover {
  color: #000;
  border: 1px solid #efefef;
  background: #e8f7f7;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.saved-btn {
  color: #fff !important;
  border: 1px solid #00509d !important;
  background: #00509d !important;
}

.saved-btn:hover {
  color: #fff !important;
  border: 1px solid #00509d !important;
  background: #006cff !important;
}

.ff-tablist {
  border-bottom: 1px solid #efefef;
  margin-top: 27px;
}

.ff-tablist .nav-item .nav-link.active,
.ff-tablist .nav-item .show > .nav-link {
  color: #00509d !important;
  border-color: #00509d;
  background-color: transparent;
}

.ff-tablist .nav-item .nav-link {
  font-size: 14px;
  font-weight: 400;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  padding: 5px 10px;
  margin-bottom: -1px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.min-height-430 {
  min-height: 430px;
}

.min-height-370 {
  min-height: 370px;
}

.min-height-180 {
  min-height: 180px;
}

.user-follow-card {
  display: flex;
  align-items: center;
  width: 100%;
}

.follow-card-left {
  display: flex;
  align-items: center;
}

.follow-avatar {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.follow-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.follow-name {
  margin-left: 15px;
}

.follow-name h5 {
  font-size: 16px;
  color: #000;
}

.follow-card-btn {
  margin-left: auto;
}

.follow-btn {
  color: #000;
  background: #fff !important;
  border-radius: 3px;
  border: 1px solid #bbb;
  padding: 3px 15px;
}

.follow-btn:hover {
  color: #000 !important;
  background: #e8f7f7 !important;
  border-radius: 3px;
  border: 1px solid #bbb;
}

/* --- Organisation Dashboard Page --- */

/* Medium devices (desktops)
==========================*/
@media (min-width: 992px) {
  body {
  }

  .logo img {
    width: 200px;
    text-align: center;
    margin-top: 10px;
    float: none;
  }

  .wrapper-body {
    margin-left: 254px;
    transition: all 0.3s ease-out;
  }

  .wrapper__minify {
    margin-left: 80px;
  }
}

.wrapper-body {
  padding: 35px 0;
}

.d-main-title h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
}

.d-main-title i {
  font-size: 22px;
}

.add-organisation-card {
  display: flex;
  width: 100%;
  align-items: center;
}

.ocard-left {
  display: flex;
  align-items: center;
}

.ocard-avatar {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.ocard-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.ocard-name {
  margin-left: 17px;
}

.ocard-name h4 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
}

.ocard-name span {
  font-size: 14px;
  color: #717171;
}

.ocard-right {
  margin-left: auto;
}

.ocard-right .co-main-btn i {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  background: #f7f7f7;
  border: 2px dashed #bbb;
  border-radius: 50%;
  line-height: 2;
  font-size: 11px;
  color: #bbb;
}

.org_design_button {
  width: 120px;
  display: inline-block;
  min-height: 120px;
  overflow: hidden;
  border: 2px dashed #bbb;
  background: #fff;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
}

.org_design_button:hover {
  background: #e8f7f7;
}

.org_design_button span {
  vertical-align: middle;
  display: block;
  text-align: center;
  color: #bbb;
  padding: 39px 0;
}

.org_design_button i {
  margin-top: 0;
  font-size: 24px;
  color: #bbb;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  outline: 0;
  background: #fff;
  cursor: inherit;
  display: block;
}

.dashboard-date-arrows a {
  font-size: 14px;
  color: #717171;
  padding: 4px 10px 2px;
  display: inline-block;
  text-align: center;
  background: #e8f7f7;
  border-radius: 3px;
}

.dashboard-date-arrows a:hover {
  color: #00509d !important;
}

.before_date.disabled,
.after_date.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.dashboard-select-date {
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 500;
}

.event-list-dropdown {
  border: 0;
  background: transparent;
  color: #000;
  font-size: 14px;
  font-family: inherit;
}

.event-list-dropdown .dropdown-item {
  padding: 2px 20px !important;
}

.dashboard-report-content {
  padding: 0 1.5rem;
}

.dashboard-report-card {
  position: relative;
  margin-bottom: 1.5rem;
  border: 1px solid #efefef;
  box-shadow: none;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.dashboard-report-card .card-content {
  color: #fff;
  flex-grow: 1;
}

.dashboard-report-card.purple {
  background-color: #8892d6;
  border-color: #8892d6;
}

.dashboard-report-card.red {
  background-color: #fc544b;
  border-color: #fc544b;
}

.dashboard-report-card.info {
  background-color: #45bbe0;
  border-color: #45bbe0;
}

.dashboard-report-card.success {
  background-color: #00509d;
  border-color: #78c350;
}

.dashboard-report-card .card-media {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  justify-content: center;
  right: 20px;
  top: 20px;
  position: absolute;
}

.dashboard-report-card .card-content {
  color: #fff;
  flex-grow: 1;
}

.dashboard-report-card .card-content .card-title {
  text-transform: uppercase;
  display: block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
}

.dashboard-report-card .card-content .card-sub-title {
  font-size: 24px;
  margin: 8px 0 13px;
  display: block;
  font-weight: 600;
}

.dashboard-report-card .card-media i {
  font-size: 28px;
  color: #fff;
}

.border_bottom {
  border-bottom: 1px solid #efefef;
}

.border_top {
  border-top: 1px solid #efefef;
}

.select-graphic-category
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 150px !important;
}

.rs .btn-check:active + .btn-outline-primary,
.rs .btn-check:checked + .btn-outline-primary,
.rs .btn-outline-primary.active,
.rs .btn-outline-primary.dropdown-toggle.show,
.rs .btn-outline-primary:active {
  color: #fff;
  background-color: #00509d;
  border-color: #00509d;
}

.rs .btn-outline-primary {
  color: #00509d;
  border-color: #00509d;
}

.rs .btn-outline-primary:hover {
  color: #00509d;
  background-color: #e8f7f7;
  border-color: #00509d;
}

.rs .btn-outline-primary:focus {
  box-shadow: none !important;
  outline: none !important;
}

.rs .btn-check:focus {
  box-shadow: none !important;
  outline: none !important;
}

.relative-input .form-control {
  padding: 0 15px 0 40px !important;
  width: 280px;
}

.relative-input i {
  position: absolute;
  top: 20%;
  font-size: 16px;
  left: 10px;
  color: #717171;
}

.custom2-tabs .tab-link {
  background: #fff;
  border: 1px solid #bbb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0;
  width: 130px;
  padding: 0 15px;
  font-size: 14px;
  height: 40px;
  color: #000;
  display: inline-block;
  margin: 0 0 0 4px !important;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.custom2-tabs .tab-link:first-child {
  margin-left: 0 !important;
}

.custom2-tabs .tab-link.active,
.tab-tabs .show > .tab-link {
  color: #fff;
  background-color: #00509d;
  border: 1px solid #00509d;
}

.custom2-tabs .tab-link:hover {
  color: #000;
  background-color: #e8f7f7;
  border: 1px solid #bbb;
}

.custom2-tabs .tab-link.active span,
.tab-tabs .show > .tab-link span {
  color: #fff;
}

.custom2-tabs .tab-link.active:hover {
  color: #fff;
  background-color: #00509d;
  border: 1px solid #00509d;
}

.event-top .card-event-img {
  width: 130px;
  height: 70px;
}

.option-btn {
  width: 35px;
  height: 35px;
  color: #000;
  border: 1px solid #bbb;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.option-btn:hover {
  color: #00509d;
  border: 1px solid #00509d;
  background: rgba(255, 255, 255, 0.3);
}

.event-list-icon {
  width: 110px;
  height: 110px;
  background: #fafafa;
  border: 1px solid #efefef;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  padding-top: 30px;
}

.event-list-icon img {
  width: 80px;
}

.contact-list {
  box-sizing: border-box;
}

.contact-list .top .icon-box {
  position: relative;
  padding-left: 66px;
  width: calc(100% - 150px);
}

.contact-list .top .icon-box .icon-big {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #d4f7f7;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 0;
}

.contact-list .top .icon-box .icon {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #d4f7f7;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 2px;
}

.icon.icon-purple {
  background-color: rgba(155, 81, 224, 0.1) !important;
}

.contact-list .top .icon-box h5 {
  font-size: 18px;
  margin: 0;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.contact-list .top .icon-box p {
  font-size: 13px;
  margin: 0 !important;
  color: #717171;
}

.option-btn-1 {
  width: 35px;
  height: 35px;
  color: #000;
  border: 1px solid #bbb;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.option-btn-1:hover {
  color: #00509d;
  border: 1px solid #00509d;
  background: rgba(255, 255, 255, 0.3);
}

.icon-box .icon {
  font-size: 18px;
  margin-right: 7px;
  padding-top: 2px;
}

.rotate-icon {
  transform: rotate(300deg);
}

.contact-list .bottom .icon-box {
  min-width: 130px;
  position: relative;
  padding-left: 52px;
  width: 25%;
  max-width: 100%;
}

.card-icon-align.bottom .icon-box {
  padding-left: 60px !important;
}

.contact-list .bottom .icon-box .icon {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: #f5f7fd;
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

.contact-list .bottom .icon-box h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  margin: 0 0 6px;
}

.contact-list .bottom .icon-box p {
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: #717171;
  margin: 0;
}

.contact-list .bottom .icon-box:not(:last-child) {
  padding-right: 15px;
}

.contact-list .bottom .icon-box .progress {
  background: rgba(163, 173, 185, 0.3);
  border-radius: 2px;
  height: 4px;
}

.contact-list .bottom .icon-box .progress {
  max-width: 80px;
}

.contact-list .bottom .icon-box .progress .progress-bar {
  background: #00509d;
  border-radius: 2px;
}

.add-contact-title h4 {
  margin-bottom: 0;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}

.gift-coupon-icon {
  width: 100%;
  text-align: center;
  padding-bottom: 0;
  border-bottom: 2px dashed #efefef;
  margin-bottom: 10px;
}

.gift-coupon-icon img {
  width: 100px;
  height: 100px;
}

.datepickers-container {
  z-index: 9999;
}

.datepicker--day-name {
  color: #00509d !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
}

.icon.icon-purple {
  color: #6b63ff;
  background-color: rgba(155, 81, 224, 0.1) !important;
}

.icon.icon-yellow {
  color: #e8df2a;
  background-color: rgba(242, 153, 74, 0.1) !important;
}

.bank-card {
  position: relative;
  border: 1px solid #efefef;
  background: #f9f9f9;
  border-radius: 3px;
}

.bank-card h5 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.bank-card h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #000;
}

.bank-card span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #717171;
}

.action-link {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  color: #717171;
  border: 1px solid #bbb;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
}

.action-link:hover {
  color: #00509d !important;
  border: 1px solid #00509d;
  background: rgba(255, 255, 255, 0.3);
}

.rmt_4 {
  margin-top: 1.5rem;
}

.table-card {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
}

.main-table .table {
  color: #000;
  margin-bottom: 0;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
}

.main-table .table > :not(:first-child) {
  border-top: 1px solid #efefef;
}

.main-table .table .thead-dark th {
  color: #000;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  border-bottom: 0;
  border: 1px;
  font-size: 14px;
  font-weight: 500;
}

.main-table .table .thead-dark th:first-child {
  border-top-left-radius: 3px;
}

.main-table .table .thead-dark th:last-child {
  border-top-right-radius: 3px;
}

.main-table .table .thead-dark tr {
  border-radius: 3px;
}

.main-table .table .thead-dark tr th:first-child {
  border-top-left-radius: 3px;
}

.main-table .table .thead-dark tr th:last-child {
  border-top-right-radius: 3px;
}

.main-table .table tbody tr td a {
  color: #000;
}

.discount-table .table td,
.discount-table .table th {
  padding: 1rem 1rem !important;
  border-top: 1px solid transparent !important;
}

.main-table .table td,
.main-table .table th {
  padding: 1rem 1rem !important;
  border-top: 1px solid transparent !important;
}

.main-table .table tfoot {
  font-weight: 500;
  padding: 14px !important;
  color: #000 !important;
}

.main-table .table tfoot td {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  background-color: #fafafa;
}

.main-table .table td {
  vertical-align: middle;
  padding: 12px 10px 9px;
  font-size: 14px;
}

.status-circle {
  background-color: #efefef;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

.red-circle {
  background-color: #dc3545;
}

.green-circle {
  background-color: #7dc579;
}

.blue-circle {
  background-color: #bb6bd9;
}

.a-link {
  color: #00509d !important;
  font-weight: 500;
}

.a-link:hover {
  color: #00509d !important;
  text-decoration: underline !important;
}

.subscription-bg {
  background: url(../images/banners/subscription-bg.png) no-repeat center/cover;
  margin-bottom: 16px;
  padding: 28px;
  min-height: 170px;
  position: relative;
}

.s-info h3 {
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

.s-info p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}

.s-price h3 {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.since-text {
  font-style: italic;
  color: #fff;
}

.subscription-title h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000;
}

.subscription-item {
  border: 1px solid #efefef;
  border-radius: 3px;
}

.subscription-item p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bp-info h4 {
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
}

.btn-gray {
  padding: 8.5px 16px;
  border-radius: 5px;
  font-weight: bold;
  border: 0;
  background: #f5f5f5;
  color: rgba(33, 33, 33, 0.6);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.btn-gray:hover.no-bg:hover svg path {
  fill: #22b0af;
}

.no-bg {
  background: none !important;
  background-color: transparent !important;
}

.c-img {
  width: 60px;
  margin-bottom: 5px;
}

.tracking-tab .tracking-head {
  background: #fff;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #efefef;
  margin-top: 1.5rem;
}

.tracking-tab .nav-tabs .nav-item {
  margin-bottom: 0px;
  width: 33.333%;
}

.tracking-tab .nav-tabs .nav-link {
  display: block;
  text-align: center;
  background: #fff;
  border: 0;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding: 0rem 1.3rem;
}

.tracking-tab .nav-tabs .nav-link.active {
  background-color: #00509d;
  color: #fff !important;
}

.specific-event {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #efefef;
}

.specific-event h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #000;
}

.organisation-profile {
  position: relative;
  padding-top: 50px;
  max-width: 560px;
  min-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.view-profile-link {
  display: inline-block;
}

.profile-edit-btn {
  position: absolute;
  right: 10px;
  top: 15px;
}

.profile-edit-btn .btn {
  color: #717171;
  font-size: 16px;
}

.profile-edit-btn .btn:hover {
  color: #00509d !important;
}

.profile-edit-btn .btn:focus {
  box-shadow: none;
}

.p-bg {
  background-color: #f5f7f9;
}

.copy-link {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
  display: inline-block;
  line-height: 36px;
  padding: 0 10px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.team-lock {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.team-lock:after {
  content: "\f023";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  float: right;
  padding-right: 30px;
  font-size: 12px;
  color: #717171;
  padding-top: 1px;
}

.team-lock > .menu--label {
  display: inline-block;
  height: 45px;
  line-height: 43px;
}

.team-lock > .menu--label:hover {
  background: #e8f7f7;
}

.upgrade-premium-area {
  justify-content: center;
  display: flex;
  padding: 30px;
}

.upgrade-content {
}

.upgrade-content .upgrade-premium-center {
  max-width: 556px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 2px 4px 7px rgb(0 0 0 / 7%);
  border-radius: 5px;
  text-align: center;
  padding: 75px 30px 30px 30px;
  background-image: url(../images/banners/up-shape.png);
  background-repeat: no-repeat;
  background-position: 30% 1%;
  overflow-y: auto;
}

.upgrade-icon img {
  width: 100px;
}

.upgrade-banner img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.upgrade-text h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 22px 0 13px;
  color: #000;
}

.upgrade-text p {
  font-size: 16px;
  font-weight: 400;
  color: #717171;
}

.premium-select {
  margin-top: 20px;
}

.premium-select .option {
  margin-top: 1.5rem;
  text-align: center;
  display: block;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  padding: 40px;
  border: 2px solid #efefef;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.premium-select .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.premium-select .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

.premium-select input[type="radio"] {
  display: none;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border: 2px solid #00509d;
  background: #fff;
}

#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #00509d;
}

#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.wrapper .option span {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  display: block;
  margin: 13px 0 10px;
}

.wrapper .option span:last-child {
  font-size: 16px;
  font-weight: 400;
  color: #717171;
  display: block;
  margin-bottom: 0;
}

#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #000;
}

#option-1:checked:checked ~ .option-1 span:last-child,
#option-2:checked:checked ~ .option-2 span:last-child {
  color: #717171;
  display: block;
}

.action-btn {
  cursor: pointer;
}

.action-btn:hover {
  color: #00509d;
}

.disabled {
  pointer-events: none !important;
}

.role-header {
  background: #efefef;
  text-align: left;
  padding: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.role-header h6 {
  font-size: 18px;
  font-weight: 500;
}

.role-body-content {
  padding: 25px;
}

.role-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.role-item:last-child {
  margin-bottom: 0;
}

.role-item i {
  color: #00509d;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(106, 192, 69, 0.1);
  border-radius: 50%;
  display: inline-block;
  font-size: 14px;
}

.i-disabled {
  color: #000 !important;
  background: rgba(0, 0, 0, 0.04) !important;
}

.role-item span {
  color: #000;
  font-weight: 400;
  margin-left: 7px;
}

.mt-30 {
  margin-top: 30px;
}

/* phone input */
.flag-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-right: 0.5em;
  }
  
  .flag-icon-us {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/us.svg');
  }
  
  .flag-icon-uk {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/gb.svg');
  }
  
  .flag-icon-in {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/in.svg');
  }
  
/*   
.flag-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-right: 0.5em;
} */
  /* Add more flag classes here for other countries */
  

  /* Style for PhoneInput component */

.ReactPhoneInput__icon {
  font-size: 1.5em;
}

.ReactPhoneInput__input {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 100%;
    height: 2.5rem;
    background-color: #fff;
    margin-bottom: 0.5rem;
  }
  
  .ReactPhoneInput__input:focus {
    outline: none;
    border-color: #3880ff;
    box-shadow: 0 0 0 2px rgba(56, 128, 255, 0.2);
  }
  .phone-input-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    /* height: 48px; */
    padding: 0 16px;
    /* border-radius: 4px; */
    /* border: 1px solid #c4c4c4; */
    /* background-color: transparent; */
    margin-bottom: 2px;
    color: #000;
    border: 2px solid #E6E6E6;
    border-radius: 99px;
    height: 54px;
    background-image: linear-gradient(0deg, #e6e6e6, #e6e6e6),
      linear-gradient(0deg, #f2f2f2, #f2f2f2);
  
  }
  
  .phone-input-container input {
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
    border: none;
    outline: none;
    background-color: transparent;
    color: inherit;
    padding: 0;
    margin: 0;
  }
  
  .phone-input-container input::placeholder {
    color: black;
  }
  
  /* Laptop styles */

/* --- Create Events --- */

.wizard-steps-block {
  margin-top: 39px;
}

#add-event-tab {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

#add-event-tab .step-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 0;
}

#add-event-tab .step-steps > li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  max-width: 0 0 33.3333%;
}

#add-event-tab .step-steps > li:before {
  background-color: #ededed;
  height: 5px;
  left: 50%;
  top: 20px;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#add-event-tab .step-steps > li > a > .number {
  width: 24px;
  height: 24px;
  background-color: #d1d1d1;
  border: 0px solid;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#add-event-tab .step-steps > li > a > .number:before {
  background-color: rgba(221, 221, 211, 0.3);
  box-shadow: 0 0 0 rgba(221, 221, 211, 0);
  height: calc(100% + 16px);
  width: calc(100% + 16px);
  top: -8px;
  left: -8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#add-event-tab .step-steps > li > a > .step-name {
  font-weight: 500;
  color: #b2b2b2;
  font-size: 14px;
  margin-top: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: capitalize;
}

#add-event-tab .step-steps > li:last-child:before {
  width: 0;
}

#add-event-tab .step-steps > li.active > a > .number,
#add-event-tab .step-steps > li.done > a > .number {
  background-color: #00509d;
}

#add-event-tab .step-steps > li.active > a > .number:before,
#add-event-tab .step-steps > li.done > a > .number:before {
  background-color: rgba(106, 192, 69, 0.3);
}

#add-event-tab .step-steps > li.active > a > .step-name,
#add-eventevent-tab .step-steps > li.done > a > .step-name {
  color: #333;
}

#add-event-tab .step-steps > li.done:before {
  background-color: #00509d;
}

#add-event-tab .step-steps > li.done > a > .number:after {
  display: none;
}

#add-event-tab > .step-content {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  border: 0px solid;
  border-radius: 0px;
  padding-top: 35px;
}

#add-event-tab > .step-content .title-icon {
  margin-bottom: 25px;
}

.p_30 {
  padding: 30px !important;
}

.pb_30 {
  padding-bottom: 30px !important;
}

.pt_30 {
  padding-top: 30px !important;
}

.pl_30 {
  padding-left: 30px !important;
}

.pr_30 {
  padding-right: 30px !important;
}

.min-h-auto {
  min-height: auto !important;
}

.clock-icon .bootstrap-select > .dropdown-toggle:after {
  content: "\f017";
  font-family: "Font Awesome 6 free";
  border: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  position: absolute;
  right: 20px;
  top: 37%;
}

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #006cff !important;
}

.datepicker--cell.-current- {
  color: #fff !important;
  background: #00509d !important;
}

.absolute-icon {
  color: #717171;
  font-weight: 600;
  position: absolute;
  background: transparent;
  right: 5px;
  top: 5px;
  min-height: 40px;
  padding: 0 15px;
  line-height: 40px;
  margin-bottom: 0;
  border-radius: 3px;
  pointer-events: none;
}

.absolute-icon:hover {
  color: #00509d;
}

.default-event-thumb {
  border: 1px dashed #bbb;
  height: 240px;
  overflow: hidden;
  position: relative;
}

.default-event-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.line-height-32 {
  line-height: 32px !important;
}

.default-event-thumb-btn {
  display: inline-block;
  color: #e44d3a;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 600;
  margin-right: 0;
  width: 100%;
}

.default-event-thumb-btn .thumb-change-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  float: left;
}

.default-event-thumb-btn .thumb-change-btn [type="file"] + label {
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 12px 30px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0;
  float: right;
}

.default-event-thumb-btn .thumb-change-btn [type="file"] + label:hover {
  background: #006cff;
  color: #fff;
}

.stepper-data-set .content-holder label {
  font-size: 14px;
  font-weight: 500;
}

.template-select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
}

.template-item img {
  display: block;
  margin: 0;
  width: 100%;
}

.template-item {
  position: relative;
}

.template-item input {
  position: absolute;
  z-index: 999;
  left: 10px;
  top: 10px;
}

.template-item input:active + .template {
  opacity: 0.9;
}

.template-item input:checked + .template {
  border-radius: 5px;
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.template {
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
}

#add-event-tab .step-footer.step-tab-pager {
  text-align: center;
}

.step-app > .step-footer {
  margin-top: 40px !important;
  margin-bottom: 0 !important;
}

.steps_btn {
  font-size: 22px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  color: #fff !important;
  background: #00509d !important;
  padding: 0px 30px !important;
  width: 290px;
  border-radius: 3px !important;
  border: 0 !important;
  height: 40px !important;
  line-height: 40px !important;
}

.steps_btn:hover {
  color: #fff !important;
  background: #006cff !important;
}

.steps_btn:focus {
  box-shadow: none !important;
}

.dropdown.bootstrap-select.dropdown-no-bg .dropdown-toggle {
  background: transparent !important;
  border: none !important;
}

.pp-select {
  position: absolute;
  right: 0;
  width: 90px;
  z-index: 999;
}

.hosting-title {
  text-align: center;
}

input[type="number"] {
  /*for absolutely positioning spinners*/
  position: relative;
  padding-right: 25px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.brn-checkbox-radio {
  display: table;
  position: relative;
  padding-left: 55px;
  cursor: pointer;
  margin-bottom: 13px;
  line-height: 23px;
}

.input-content {
  padding-left: 75px;
}

.percentage-icon {
  color: #717171;
  font-weight: 600;
  position: absolute;
  background: transparent;
  right: 0px;
  top: 1px;
  min-height: 40px;
  padding: 0px 15px;
  line-height: 40px;
  margin-bottom: 0;
  border-radius: 3px;
  pointer-events: none;
  z-index: 999;
  font-size: 18px;
}

.sub-label {
  color: #717171;
}

.form-check-input:checked {
  background-color: #00509d !important;
  border-color: #00509d !important;
  outline: #a2ff7a !important;
  box-shadow: none !important;
}

.form-check-input {
  width: 1.2em !important;
  height: 1.2em !important;
}

.refund-method:first-child .refund-input-content {
  display: block;
}

.refund-method .refund-input-content {
  display: none;
}

.br-checkbox {
}

.ticket-instructions-content {
  padding-left: 55px;
}

.tags-list {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 8px;
}

.tag {
  background-color: rgba(42, 65, 232, 0.07);
}

.tag {
  background-color: rgba(106, 192, 69, 0.07);
  color: #00509d;
  display: inline-block;
  padding: 0;
  border-radius: 3px;
  cursor: default;
  margin: 0 7px 7px 0;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  animation: KeywordIn 0.3s ease-in-out;
  animation-fill-mode: both;
  transition: 0.4s;
  overflow: hidden;
  max-width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.tag-text {
  padding-right: 12px;
}

.tag .tag-remove:before {
  display: inline-block;
  cursor: pointer;
  font-family: "Font Awesome 6 Free";
  content: "\f00d";
  line-height: 20px;
  position: relative;
  top: 0px;
  margin-right: 7px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 900;
}

#map {
  height: 250px;
  width: 100%;
}

.full-width {
  width: 100% !important;
}

.price-ticket-card {
  border: 1px solid #efefef;
  border-radius: 3px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.ticket-list-icon {
  width: 110px;
  height: 110px;
  background: #fafafa;
  border: 1px solid #efefef;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  padding-top: 0;
  line-height: 110px;
}

.ticket-list-icon img {
  width: 70px;
}

.price-badge {
  width: 24px;
  height: 24px;
  padding-top: 2px;
  margin-right: 15px;
}

.price-badge img {
  width: 100%;
  object-fit: cover;
}

.price-ticket-card-head .top-name .icon-box .icon {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #d4f7f7;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 2px;
}

.price-ticket-card-head .top-name .icon-box {
  position: relative;
  padding-left: 66px;
}

.price-ticket-card .price-ticket-card-body .text-145 {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #717171;
  margin: 0;
}

.price-ticket-card .price-ticket-card-body h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  margin: 0 0 6px;
}

.price-ticket-card .price-ticket-card-body .icon-box:not(:last-child) {
  padding-right: 15px;
}

.price-ticket-card .price-ticket-card-body .icon-box {
  position: relative;
  padding-left: 52px;
  width: 33.3333%;
  max-width: 100%;
}

.price-ticket-card .price-ticket-card-body .icon-box .icon {
  display: inline-block;
  font-size: 14px;
  height: 36px;
  width: 36px;
  color: #717171;
  background: #f5f7fd;
  border-radius: 100px;
  text-align: center;
  line-height: 35px;
  position: absolute;
  left: 0;
  top: 0;
}

.price-variation-head {
  padding: 16px;
  background: #f5f8ff;
  box-shadow: 0px -1px 0px rgb(0 0 0 / 6%);
  filter: drop-shadow(0px -10px 10px rgba(0, 0, 0, 0.05));
  margin-top: 20px;
}

.add-btn {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #00509d;
  border-radius: 3px;
  cursor: pointer;
}

.div-base-table.small-table {
  min-width: 440px;
}

.div-base-table {
  display: table;
  min-width: 873px;
  border: 1px solid #dddfe2;
}

.div-base-table .table-row.table-head {
  background: #fafafa;
  color: #000;
  font-weight: 500;
  font-size: 13px;
}

.div-base-table .table-row {
  display: table-row;
  font-size: 12px;
  color: #4b4c4c;
}

.no-bg {
  background: none !important;
  background-color: transparent !important;
}

.div-base-table .table-row .table-col {
  display: table-cell;
  padding: 10px 10px 10px 0px;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 0 #dddfe2;
  -moz-box-shadow: 0 1px 0 #dddfe2;
  box-shadow: 0 1px 0 #dddfe2;
  overflow: hidden;
}

.text-light3 {
  color: #000 !important;
}

.div-base-table .table-row {
  display: table-row;
  font-size: 12px;
  color: #000;
}

.v-btn-close {
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 42px;
  text-align: center;
  background: #00509d;
  border-radius: 3px;
  border: 0;
  font-size: 18px;
}

.top_0 {
  top: 0 !important;
}

/*--- Pricing Page ---*/

.hero-side-banner {
  width: 100%;
  height: 250px;
  padding: 30px;
  background: #e8f5f7;
  border-radius: 10px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.hero-side-banner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.flag-icon img {
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}

.text-icon {
  margin-bottom: 0 !important;
  border-left: 3px solid #00509d;
  padding-left: 10px;
}

.flag-icon {
  margin: 0 5px;
}

.sell-calculate-banner-bg {
  background: #00509d;
  max-width: 300px;
  display: inline-block;
  width: 100%;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 10px 16px 0px rgb(64 70 74 / 15%) !important;
}

.sell-calculate-banner-content {
  color: #fff;
}

.sell-calculate-banner-content h3 {
  color: #ffffff;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 50px;
}

.sell-calculate-banner-content h5 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.sell-calculate-banner-content .plus {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
}

.sell-calculate-banner-content .per-text {
  color: #fff;
  font-size: 18px;
  margin: 13px 0;
  display: block;
}

.percent {
  line-height: 40px;
  margin: 10px 0px 0px 0px;
  padding: 7px 15px 5px 15px;
  background-color: #1c2131;
  border-radius: 8px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
}

.sell-calculate-banner-content p {
  color: #ffffff;
  margin-bottom: 0;
}

.sw-container {
  padding: 4px 4px;
  overflow: hidden;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field input:checked + label {
  background-color: #00509d;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  text-transform: capitalize;
  border: 1px solid #00509d;
}

.switch-field label {
  border-radius: 4px;
  margin-bottom: 0;
  border: 1px solid #efefef;
  border-radius: 3px;
  background: #fff;
  cursor: pointer;
}

.switch-field label {
  display: inline-block;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-shadow: none;
  padding: 15px 15px;
  height: 50px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  /* width: 46%; */
  margin-right: 10px;
  width: calc(50% - 8px);
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calculate-top {
  background: #f5f7f9;
  padding: 5px 30px 30px;
  border-radius: 5px;
}

.price-ticket-box {
  display: flex;
  width: 100%;
  height: 167px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.data-amount {
  margin: auto;
}

.data-amount h4 {
  font-size: 30px;
  font-weight: 600;
}

.data-amount span {
  display: block;
  margin-top: 10px;
  color: #717171;
}

.accordion-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 1.25rem;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.accordion-item:first-of-type .accordion-btn {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-btn:not(.collapsed) {
  color: #000;
  background-color: transparent;
  box-shadow: none;
}

.accordion-btn:not(.collapsed)::after {
  content: "\f068";
}

.accordion-btn::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "\2b";
  font-family: "FontAwesome";
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.faq-accordion .accordion-item {
  background: transparent;
  border-top: 1px solid #cbcbcb;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.faq-accordion .accordion-item:last-child {
  border-bottom: 1px solid #cbcbcb;
}

.faq-accordion .accordion-item:not(:first-of-type) {
  border-top: 1px solid #cbcbcb;
}

.accordion-body p {
  line-height: 28px;
}

/*--- FAQ Page ---*/

.faq-widget-links li {
  margin-bottom: 15px;
}

.faq-widget-links li:last-child {
  margin-bottom: 0;
}

.faq-widget-links li p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  display: block;
  margin-bottom: 0;
}

.faq-widget-links li a {
  color: #000;
}

.faq-widget-links li a:hover {
  color: #00509d !important;
}

.cant-ans-box a {
  font-size: 16px;
  margin-top: 1.5rem;
  display: block;
  color: #00509d;
  font-weight: 500;
}

.cant-ans-box a:hover {
  color: #006cff !important;
}

.faq-accordion-title h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 23px;
}

.faq-attendees-accordion {
  margin-top: 57px;
}

/*--- Contact Us Page ---*/

.contact-banner-block {
  padding: 5px;
}

.contact-hero-banner {
  min-height: 461px;
  background-color: #00509d;
  width: 100%;
  padding: 50px;
  border-radius: 3px;
}

.contact-hero-banner-info h3 {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}

.contact-hero-banner-info p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 60px;
}

.contact-icon i {
  color: #fff;
  font-size: 20px;
  margin-right: 17px;
}

.contact-info a {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.contact-info a:hover {
  color: #fff !important;
}

.contact-hero-banner-info ul li {
  margin-bottom: 20px;
}

.contact-hero-banner-info ul li:last-child {
  margin-bottom: 0;
}

/*--- Our Blog Page---*/

.bt_33 {
  margin-top: 33px;
}

.bt_40 {
  margin-top: 40px;
}

.blog-thumbnail {
  width: 100%;
  position: relative;
  padding: 0;
}

.blog-img {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.blog-thumbnail > .thumbnail-img::after {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.blog-img:hover {
  outline: 0 !important;
  color: #000;
  transition: 0.3s;
  text-decoration: none;
}

.blog-thumbnail a img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: transform 0.5s ease;
}

.blog-thumbnail a:hover img {
  transform: scale(1.05);
}

.post-image a img {
  height: 531px;
  object-fit: initial;
}

.blog-content {
  padding: 15px 20px 20px;
}

.blog-title {
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
  display: block;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-title:hover {
  color: #000 !important;
}

.post-meta .post-date {
  position: relative;
}

.post-read-time {
  float: right;
}

.Bp-top {
  margin-top: 55px;
}

.flex-slide {
  display: flex;
  align-items: stretch;
  transition: 300ms;
  position: relative;
}

.flex-slide .left {
  flex: 0 1 50%;
}

.blog-img-card {
  padding: 0;
}

.bbig-img:hover {
  outline: 0 !important;
  color: #000;
  transition: 0.3s;
  text-decoration: none;
}

.blog-img-card img {
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.flex-slide .right {
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
}

.flex-slide .right h3 {
  font-size: 24px;
  text-align: left;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.flex-slide .right .blog--title {
  font-size: 24px;
  text-align: left;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.flex-slide .right .blog--title:hover {
  color: #000 !important;
}

.blog-block .right .post-meta span {
  font-size: 16px;
}

.blog-block .right p {
  font-size: 16px;
}

/* --- Blog Detail Page --- */

.blog-content-view {
  margin-top: 40px;
}

.blog-content-view h3 {
  font-size: 24px;
  text-align: left;
  font-weight: 600;
  color: #000;
  margin-bottom: 35px;
}

.blog-content-view .blogpost-meta {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #efefef;
  padding-bottom: 21px;
}

.blog-content-meta {
  margin-top: 33px;
}

.blog-content-meta p {
  margin-bottom: 24px;
  line-height: 28px;
}

.blog-description-article h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
  margin-top: 30px;
  color: #000;
}

.blog_pagination {
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0;
}

.bp_left {
  float: left;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  z-index: 1;
}

.bp_left i {
  font-size: 24px;
  color: #000;
}

.bp_right {
  float: right;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  z-index: 1;
}

.bp_right i {
  font-size: 24px;
  color: #000;
}

.kslu15 {
  margin-left: 15px;
  text-align: left;
}

.blog-description-list {
  margin-bottom: 32px !important;
  padding-left: 15px !important;
}

.blog-description-list li {
  list-style: circle;
  margin-bottom: 10px;
}

.blog-description-list li:last-child {
  margin-bottom: 0;
}

.social-share {
  border-left: 3px solid #00509d;
  padding-left: 20px;
  margin: 45px 0 25px;
}

.social-share h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
}

.social-share ul li {
  display: inline-block;
  margin-right: 20px;
}

.share-link {
  font-size: 24px;
  color: #00509d;
}

.share-link:hover {
  color: #006cff !important;
}

/* --- Help Center Page --- */

.help-center-logo {
  font-size: 14px;
  padding: 10px 10px 0;
  color: #00509d;
}

.help-item {
  padding: 1.5rem;
}

.help-item-content h4 {
  font-size: 16px;
  font-weight: 600;
}

.help-item-content {
  margin-left: 20px;
}

.help-item-icon {
  background: #e8f7f7;
  width: 42px;
  height: 42px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #717171;
}

.help-item-content span {
  color: #717171;
}

.help-center-title h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 27px;
  padding-bottom: 23px;
}

.help-item-link {
  font-size: 16px;
  color: #000;
  display: block;
  padding: 1.5rem;
  border-bottom: 1px solid #efefef;
}

.help-item-link:hover {
  color: #00509d !important;
}

.help-item-link:last-child {
  border-bottom: 0;
}

.upload-file-dt {
  background: #fff;
  border: 2px dashed #efefef;
  border-radius: 5px;
  text-align: center;
  padding: 30px 20px 17px;
}

.upload-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

.upload-btn [type="file"] + label {
  cursor: pointer;
  display: inline-block;
  outline: none;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0 0 17px;
  color: #fff;
  background-color: #00509d;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding: 10px 25px;
  border-radius: 3px;
  text-transform: uppercase;
}

.upload-btn [type="file"] + label:hover {
  color: #fff !important;
  background: #006cff;
}

.upload-file-dt span {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  display: block;
  color: #000;
  background-color: transparent;
  overflow: hidden;
  line-height: 26px;
}

.uploadBtn-file-name {
  display: block;
  color: #717171;
  background-color: transparent;
  overflow: hidden;
  line-height: 26px;
}

.upload-btn small {
  display: block;
  color: #717171;
}

.help-faq-content {
  padding: 10px 0;
}

.help-faq-link {
  color: #000;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 20px;
}

.help-faq-link:hover {
  color: #00509d !important;
}

.help-faq-link:before {
  content: "\f15c";
  font-family: "FontAwesome";
  font-weight: 400;
  margin-right: 15px;
}

.help-v-content p {
  margin-bottom: 27px;
}

.help-v-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}

.helful-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 5px;
}

.helpful-btn {
  color: #00509d;
  margin: 0 5px;
  font-size: 16px;
}

.helpful-btn:hover {
  color: #006cff !important;
}

.helpful-btn span {
  font-weight: 500;
}

.help-pagination-link {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  color: #717171;
}

.help-pagination-link:hover {
  color: #00509d !important;
}

.kb-card {
  display: block;
}

.kb-content p {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* --- About Us Page --- */

.about--description {
  margin-top: 40px;
}

.about--description p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 21px;
}

.about--description p:last-child {
  margin-bottom: 0;
}

.communities-steps {
  margin-top: 10px;
}

.communities-item {
  display: block;
  margin-top: 30px;
  height: 470px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.communities-img {
  padding: 10px 10px 0;
}

.communities-img img {
  width: 100%;
  border-radius: 5px;
}

.communities-content p {
  margin-bottom: 0;
  padding: 20px;
  font-size: 16px;
  line-height: 30px;
  color: #000;
}

.feature-step-link {
  width: 20%;
}

.feature-img img {
  height: 100%;
  width: 100%;
  margin-top: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.custom-border-top {
  border-top: 2px solid #efefef;
  padding-top: 3px;
}

.team-card {
  margin-top: 30px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.user-social-link {
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 2px solid #00509d;
  border-radius: 100%;
  text-align: center;
  line-height: 39px;
  color: #00509d;
  margin-right: 7px;
}

.user-social-link:hover {
  border: 2px solid #00509d;
  background-color: #00509d;
  color: #fff !important;
  margin-right: 7px;
}

.team-img {
  padding: 10px 10px 0;
}

.team-img img {
  width: 100%;
  border-radius: 5px;
}

.team-content {
  text-align: center;
  padding: 20px;
}

.team-content h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
  color: #000;
}

.team-content span {
  margin-bottom: 0;
  font-size: 14px;
  color: #717171;
  letter-spacing: 0.1px;
}

.user-social-links-group {
  margin-top: 23px;
}

/* --- Sell Tickets Online Page --- */

.easy-steps-list {
  padding-top: 20px;
}

.easy-step {
  padding: 30px;
  margin-top: 1.5rem;
  height: 290px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.easy-icon {
  display: block;
  margin-bottom: 24px;
}

.easy-icon img {
  width: 50px;
}

.easy-step h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
  color: #000;
}

.easy-step p {
  margin-bottom: 0;
}

.bg-dark {
  background: #1c2131;
}

.c-white {
  color: #fff !important;
}

.c-bbb {
  color: rgba(255, 255, 255, 0.8) !important;
}

.bg-dark .step-tabs .step-link:hover {
  background-color: #313539;
}

.bg-dark .step-link {
  border-bottom: 1px solid #52565b;
}

.bg-dark .step-tabs .step-link.active:hover {
  color: #fff;
  background-color: #00509d;
}

/* --- Create Page --- */

.create-block {
  margin-top: 20px;
}

.create-card {
  text-align: center;
  padding: 30px;
  box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%);
}

.create-icon {
  display: inline-block;
}

.create-icon i {
  font-size: 20px;
  color: #000;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #e8f7f7;
  border-radius: 50%;
  margin-bottom: 22px;
}

.create-card h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 27px;
  color: #000;
}

/* --- Coming Soon Page --- */

.coming-soon-area {
  height: 100vh;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.coming-soon-content {
  max-width: 700px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 60px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
  box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
  margin: auto;
}

.coming-soon-content h2 {
  font-size: 40px;
  margin-top: -10px;
  margin-bottom: 0;
  font-weight: 600;
}

.coming-soon-content #timer div span {
  display: block;
  margin-top: -2px;
  font-weight: 400;
  font-size: 14px;
}

.coming-soon-content #timer {
  margin-top: 40px;
}

.coming-soon-content #timer div {
  border: 2px dashed #00509d;
  color: #00509d;
  width: 100px;
  height: 105px;
  border-radius: 5px;
  font-size: 40px;
  font-weight: 800;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
}

.subscribe-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}

/* --- Error 404 Page --- */

.error-404-content h2 {
  font-size: 130px;
  font-weight: 800;
  letter-spacing: 10px;
}

.error-404-content h4 {
  font-size: 24px;
  margin-bottom: 21px;
}

.error-404-content p {
  font-size: 16px;
  padding: 0 50px;
  margin-bottom: 37px;
}

/* --- Refer a Friend Page --- */

.total-refer-count h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
}

.total-refer-count span {
  color: #717171;
  font-size: 16px;
  margin-top: 10px;
  display: block;
}

.refer-card {
  background: linear-gradient(
    203.73deg,
    rgba(9, 164, 219, 0.2) 2.53%,
    rgba(34, 216, 143, 0.2) 122.47%
  );
}

.refer-content h5 {
  margin-bottom: 20px;
}

.refer-content h3 {
  font-weight: 600;
  margin-bottom: 30px;
}

.refer-copy-input h5 {
  margin-bottom: 10px;
  font-weight: 400;
}

.referral-icon {
  font-size: 24px;
  width: 70px;
  height: 70px;
  background: #fafafa;
  border: 1px solid #efefef;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  padding-top: 0;
  line-height: 70px;
}

/* --- Terms & Condition Page --- */

.term-link {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: block;
  border: none;
  margin-bottom: 15px;
  padding: 0;
  color: #000;
  background: transparent;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.term-link:last-child {
  margin-bottom: 0;
}

.terms-tabs .term-link.active,
.terms-tabs .show > .term-link {
  color: #00509d !important;
}

.terms-tabs .term-link:hover {
  color: #006cff !important;
}

.terms-tabs .term-link.active:hover {
  color: #00509d !important;
}

.terms-main-title h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 17px;
}

.terms-content p {
  margin-bottom: 24px;
  line-height: 28px;
}

.terms-content ol {
  display: block;
  list-style-type: decimal;
}

.terms-content ul,
ol {
  padding: 0 0 0 20px !important;
  margin-left: 0;
}

.terms-content ol li {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 13px;
  margin-top: 27px;
  font-weight: 500;
}

.terms-content ul li {
  font-size: 14px;
  line-height: 26px;
  color: #717171;
}

.terms-content ul {
  margin-bottom: 27px !important;
  display: block;
  list-style-type: disc;
}

.terms-content ul:last-child {
  margin-bottom: 0 !important;
  display: block;
}

.terms-content ul li ul {
  list-style-type: revert;
  margin-top: 10px;
}

.terms-content ul li ul li {
  margin-bottom: 10px;
}

.terms-content ul li ul li:last-child {
  margin-bottom: 31px;
}

/* --- Privacy Policy Page --- */

.privacy-main-title h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 27px;
}

.privacy-content h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  padding-top: 7px;
}

.privacy-content p {
  margin-bottom: 25px;
  line-height: 28px;
}

.privacy-content span {
  display: block;
  margin-bottom: 13px;
}

.privacy-content span:last-child {
  margin-bottom: 0;
}

/* --- Booking Confirmed Page --- */

.booking-confirmed-img {
  margin-bottom: 27px;
}

.booking-confirmed-img img {
  width: 60px;
}

.booking-confirmed-top h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.add-calender-booking {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.add-calender-booking h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.cb-icon {
  color: #717171;
  font-size: 14px;
  width: 30px;
  height: 30px;
  background: #e8f7f7;
  margin-left: 10px;
  line-height: 30px;
  border-radius: 50%;
}

.cb-icon:hover {
  color: #fff !important;
  background: #00509d;
}

.booking-confirmed-bottom {
  padding: 0 5px 5px;
}

.booking-confirmed-bottom-bg {
  background: #f9f9f9;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.buyer-name {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  font-family: inherit;
}

.booking-total-tickets span {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-top: 13px;
}

.booking-total-grand {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  margin-top: 13px;
}

.booking-total-grand span {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-left: 2px;
}

/* --- Invoice Page --- */

.invoice-header {
  background: #1c2131;
  display: flex;
  width: 100%;
  align-items: center;
  height: 60px;
  padding: 5px 20px;
}

.invoice-header-logo img {
  width: 130px;
}

.download-link {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 500;
}

.download-link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.invoice-body {
  padding: 30px;
  background: #fff;
  float: left;
  width: 100%;
}

.invoice_title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  color: #000;
  text-align: left;
}

.vhls140 {
  width: 100%;
  margin-top: 30px;
}

.vhls140 ul li {
  display: block;
  margin-bottom: 5px;
}

.vdt-list {
  font-size: 14px;
  font-weight: 400;
  color: #717171;
  text-align: left;
  line-height: 24px;
}

.totalinv2 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 8px;
}

.user_dt_trans {
  padding: 10px;
}

.user_dt_trans p {
  margin-bottom: 0;
}

.cut-line {
  position: relative;
  border-bottom-width: 1px;
  border-top: 0;
  border-radius: 0;
  border-left: 0;
  border-style: dashed;
  height: 1px;
  color: #bbb;
  margin-top: 30px;
  margin-bottom: 30px;
}

.cut-line i {
  position: absolute;
  top: -6px;
  background: #fff;
  width: 16px;
}

.QR-dt {
  height: 100%;
  background: #f9f9f9;
}

.QR-scanner {
  background: #fff;
  display: inline-block;
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 3px;
}

.QR-scanner img {
  width: 75px;
}

.QR-dt p {
  margin-bottom: 0;
  font-size: 12px;
  margin-top: 5px;
}

.QR-counter-type li {
  margin-bottom: 3px;
  color: #000;
}

.QR-counter-type li:first-child {
  margin-bottom: 17px;
  font-weight: 500;
  font-size: 16px;
}

.QR-counter-type li:last-child {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
}

.featured-controls label {
  margin: 0;
  cursor: pointer;
  text-align: center;
}

.featured-controls label:first-child {
  margin-left: 0;
}

.featured-controls label span {
  font-size: 12px;
  color: #717171;
  border: 1px solid #efefef;
  background: #fff;
  height: 32px;
  padding: 7px 20px;
  border-radius: 30px;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
}

.featured-controls label input {
  position: absolute;
  top: -20px;
}

.featured-controls label input {
  position: absolute;
  top: -20px;
}

.featured-controls input:checked + span {
  background: #efefef;
  color: #000 !important;
}

.event-box {
  padding: 0;
  display: none;
  margin-top: 0;
}

.map iframe {
  width: 100%;
  height: 250px;
}
